import React, { useEffect } from "react";
import "./MainPage.css";

//Components
import ThreeJSComponent from "../Components/ThreeJSComponent";
import OverlayUI from "../Components/OverlayUI";

function MainPage() {
  useEffect(() => {
    // Add the scroll event listener
    const handleScroll = () => {
      console.log("Scrolling detected!");
      // Perform any actions on scroll here
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="main-page" style={{ overflowY: "auto", height: "100vh" }}>
      <ThreeJSComponent />
      <OverlayUI />
    </div>
  );
}

export default MainPage;
