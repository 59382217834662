import React, { useState } from "react";
import VideoComponentVert from "./VideoComponentVert";

export default function ARCard({ videoLink, mobile }) {
  const [isLoaded, setIsLoaded] = useState(false);

  // Function to handle video load
  const handleVideoLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div>
      <div className={mobile ? "ARCard_mobile" : "ARCard"}>
        <div className={`ARCard fade-in ${isLoaded ? "loaded" : ""}`}>
          <VideoComponentVert videoRef={videoLink} onLoad={handleVideoLoad} />
        </div>
      </div>
    </div>
  );
}
