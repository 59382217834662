import React from "react";

const VideoComponentVert = ({ videoRef, onLoad }) => {
  const autoplayAndMuteVideoURL = `${videoRef}?autoplay=1&mute=1`;

  return (
    <video
      className="videoPlayerVert"
      title="Video Player"
      src={videoRef}
      autoPlay
      muted
      loop // Optional: if you want the video to loop
      playsInline // Optional: for mobile optimization (avoids full-screen mode by default)
      //controls // Optional: if you want to show video controls
      onLoadedData={onLoad}
    />
  );
};

export default VideoComponentVert;
