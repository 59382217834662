import React, { useState, useEffect } from "react";

import SocialARGrisDesktop from "./SocialARGris_desktop";
import SocialArGrid_mobile from "./SocialArGrid_mobile";

export default function PrevSocialAR() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768); // Default to desktop or mobile based on window width

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768); // Update state based on screen width
    };

    window.addEventListener("resize", handleResize); // Listen for resize events
    return () => window.removeEventListener("resize", handleResize); // Clean up on component unmount
  }, []);

  return <>{isDesktop ? <SocialARGrisDesktop /> : <SocialArGrid_mobile />}</>;
}
