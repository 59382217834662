import React from "react";
import ARCard from "./ARCard";
import AReffectsList from "./AREffectsLib.json";

export default function SocialARGrisDesktop() {
  return (
    <div className="ar-grid">
      <div className="ar-card-container">
        {AReffectsList.slice(0, 3).map((item) => (
          <ARCard key={item.id} videoLink={item.videoLink} mobile={false} />
        ))}
      </div>
      <div className="ar-card-container">
        {AReffectsList.slice(3, 6).map((item) => (
          <ARCard key={item.id} videoLink={item.videoLink} mobile={false} />
        ))}
      </div>
      <div className="ar-card-container">
        {AReffectsList.slice(6, 9).map((item) => (
          <ARCard key={item.id} videoLink={item.videoLink} mobile={false} />
        ))}
      </div>
    </div>
  );
}
