import React from "react";
import ARCard from "./ARCard";
import AReffectsList from "./AREffectsLib.json";

export default function SocialArGrid_mobile() {
  return (
    <div className="mobile-ar-grid">
      <div className="mobile-ar-card-scroll-container">
        {AReffectsList.map((item) => (
          <ARCard key={item.id} videoLink={item.videoLink} mobile={true} />
        ))}
      </div>
    </div>
  );
}
